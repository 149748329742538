import { SET_CHAMBER } from './-mutation-types';
import { useChambersStore } from '~/stores/chambers';
import type { Chamber } from '~/models/Chamber';

const Mutations = {
    [SET_CHAMBER](payload: Chamber | null) {
        const chambersStore = useChambersStore();
        chambersStore.chamber = payload;
    },
};

export default Mutations;
