import { SET_CHAMBER } from './-mutation-types';
import { useChambersStore } from '~/stores/chambers';
import ChamberService from '~/services/api/ChamberService';
import type { Chamber } from '~/models/Chamber';

const Actions = {
    async fetchChamber(chamberId: number) {
        const chambersStore = useChambersStore();
        const chamberService = new ChamberService();
        const response = await chamberService.fetchChamber(chamberId);

        chambersStore[SET_CHAMBER](response);
    },
    setChamber(chamber: Chamber) {
        const chambersStore = useChambersStore();
        chambersStore[SET_CHAMBER](chamber);
    },
};

export default Actions;
