/* eslint-disable vue/sort-keys */

import { defineStore } from 'pinia';
import { DefaultState } from './chambers/state';
import Getters from './chambers/getters';
import Actions from './chambers/actions';
import Mutations from './chambers/mutations';

export const useChambersStore = defineStore(
    'chambers',
    {
        state: DefaultState,
        getters: Getters,
        actions: {
            ...Actions,
            ...Mutations,
        },
    },
);
