import type { Chamber } from '~/models/Chamber';

export type ChamberState = {
    chamber: Chamber | null;
}

export const defaultStateData = (): ChamberState => ({
    chamber: null,
});

export const DefaultState = (): ChamberState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
