import { GET_CHAMBER } from './-getter-types';
import { useChambersStore } from '~/stores/chambers';
import type { Chamber } from '~/models/Chamber';

const Getters = {
    [GET_CHAMBER]: (): Chamber | null => {
        const chambersStore = useChambersStore();

        return chambersStore.chamber;
    },
};

export default Getters;
